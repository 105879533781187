import axios from 'axios'

const state = {
    currentUser: null,
    errorMessage: '',
    axiosError: null,
};

const getters = {
    currentUser(state) {
        return state.currentUser;
    },
    isLoggedIn(state) {
        return state.currentUser && !!state.currentUser.username;
    },
    currentUserErrorMessage(state) {
        return state.errorMessage;
    },
    currentUserShowError(state) {
        return !!state.errorMessage;
    }
}

const actions = {
    fetchCurrentUser(context, params) {
        context.commit('setCurrentUser', null);

        const baseUrl = params.baseUrl;
        const getCurrentUserUrl = baseUrl + 'utils/current-user/';

        const axiosConfig = {
            headers: {'X-Requested-With': 'XMLHttpRequest'},
        };

        axios.get(getCurrentUserUrl, axiosConfig)
            .then(response => {
                context.commit('setCurrentUser', response.data);
            })
            .catch(error => {
                context.commit('setErrorMessage', 'Error getting user information.');
                context.commit('setAxiosError', error)
            });
    }
};

const mutations = {
    setCurrentUser(state, user) {
        state.currentUser = user;
    },
    setErrorMessage(state, errorMessage) {
        state.errorMessage = errorMessage;
    },
    setAxiosError(state, error) {
        state.axiosError = error;
    }
};

export default {
    namespaced: false, // TODO: better to use namespacing?
    state,
    getters,
    actions,
    mutations
};
