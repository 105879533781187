<template>
    <v-card>
        <v-card-title class="mb-1">Add Song</v-card-title>
        <v-card-text>
            <v-card min-height="45" flat tile>
                <v-alert
                    type="error"
                    outlined
                    dense
                    :value="!!errorMessage"
            >
                {{ this.errorMessage }}
            </v-alert>
            </v-card>

            <v-text-field
                    v-model="fields.title"
                    label="Title"
                    required
                    @input="clearErrors"
                    @blur="clearErrors"
            >
            </v-text-field>
            <v-text-field
                    v-model="fields.authors"
                    label="Authors"
            >
            </v-text-field>
            <v-text-field
                    v-model="fields.copyright"
                    label="Copyright"
            >
            </v-text-field>
            <v-textarea
                    v-model="fields.notes"
                    label="Notes"
                    no-resize
                    rows="4"
            >
            </v-textarea>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                    @click="cancelCreateSong"
                    class="mb-4 mr-4"
                    :disabled="submitInProgress"
            >
                Cancel
            </v-btn>
            <v-btn
                    @click="createSong"
                    color="primary"
                    class="mb-4 mr-4"
                    :disabled="submitInProgress"
            >
                Add Song
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
  import axios from "axios";

  export default {
    data: () => ({
      baseUrl: '/',
      submitInProgress: false,
      error: null,
      errorMessage: '',
      fields: {
          title: '',
          authors: '',
          copyright: '',
          notes: '',
      }
    }),
    computed: {
        getCreateSongUrl: function() {
            return this.baseUrl + 'songs/api/songs/';
        },
        getPostData: function() {
            return {
                title: this.fields.title,
                authors: this.fields.authors,
                copyright: this.fields.copyright,
                notes: this.fields.notes,
                text: '',
            }
        },
    },
    methods: {
      createSong: function() {
          this.submitInProgress = true;

        const axiosConfig = {
            headers: {'X-Requested-With': 'XMLHttpRequest'},
        };

        axios.post(this.getCreateSongUrl, this.getPostData, axiosConfig)
            .then(response => {
                response;
                this.clear();
                this.clearErrors();
                this.$emit('add-song');
            })
            .catch(error => {
                // TODO: do something
                this.error = error;
                this.errorMessage = 'There was a problem adding the song.'
            }).finally(() => {
                this.submitInProgress = false;
            });
      },
      cancelCreateSong: function() {
          this.clear();
          this.clearErrors();
          this.$emit('canceled-add-song');
      },
      clear: function() {
          this.fields.title = '';
          this.fields.authors = '';
          this.fields.copyright = '';
          this.fields.notes = '';
      },
      clearErrors: function() {
          this.error = null;
          this.errorMessage = '';
      }
    }
  }
</script>
