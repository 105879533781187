<template>
    <v-card v-if="song" flat tile>
        <v-snackbar
                v-model="showSuccessMessage"
                top
                right
                color="success"
                class="mt-12"
        >
            <span>{{ successMessage }}</span>
        </v-snackbar>
        <v-list-item class="px-0">
            <v-list-item-content class="py-0">
                <v-text-field
                        v-model="songTitle"
                        label="Title"
                        solo
                        flat
                        hide-details
                        dense
                        class="headline pt-0"
                        :prepend-icon="isEditingSongTitle ? 'mdi-pencil' : 'mdi-file-music'"
                        @click="isEditingSongTitle = true"
                        @blur="isEditingSongTitle = false"
                ></v-text-field>
                <v-list-item-subtitle>
                    <v-text-field
                        v-model="songAuthors"
                        solo
                        flat
                        hide-details
                        dense
                        :prepend-icon="isEditingSongAuthors ? 'mdi-pencil' : 'mdi-account-box-multiple'"
                        @click="isEditingSongAuthors = true"
                        @blur="isEditingSongAuthors = false"
                ></v-text-field>
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-card-text class="mt-1 py-0 px-0">
            <v-btn
                    @click="updateSong"
            >
                Save Changes
            </v-btn>
            <v-btn
                    :href="getSongPdfUrl"
                    target="_blank"
                    class="ml-5"
            >
                <v-icon left>mdi-adobe-acrobat</v-icon>
                Generate PDF
            </v-btn>
        </v-card-text>
        <v-card-text class="px-0">
            <v-card flat>
                <v-textarea
                    v-model="songText"
                    auto-grow
                    solo
                    no-resize
                    rows="40"
                >
                </v-textarea>
            </v-card>
        </v-card-text>
    </v-card>
</template>

<script>
    import { mapGetters } from 'vuex';
    import axios from "axios";

    axios.defaults.xsrfHeaderName = "X-CSRFToken";
    axios.defaults.xsrfCookieName = "csrftoken";
    axios.defaults.withCredentials = true;

    export default {
        props: {
            id: Number,
        },
        data: () => ({
            baseUrl: '/',
            song: null,
            isEditingSongTitle: false,
            isEditingSongAuthors: false,
            songText: '',
            songTitle: '',
            songAuthors: '',

            showSuccessMessage: false,
            successMessage: '',
        }),
        computed: {
            getSongUrl: function () {
                return this.baseUrl + 'songs/api/songs/' + this.id + '/';
            },
            getSongPdfUrl: function () {
                return this.baseUrl + 'songs/' + this.id + '/pdf/';
            },
            ...mapGetters([
                'currentUser',
                'isLoggedIn',
            ])
        },
        methods: {
            loadSong: function() {
                const axiosConfig = {
                    headers: {'X-Requested-With': 'XMLHttpRequest'},
                };

                axios.get(this.getSongUrl, axiosConfig)
                    .then(response => {
                        this.song = response.data;
                        this.songText = this.song.text;
                        this.songTitle = this.song.title;
                        this.songAuthors = this.song.authors;
                    })
                    .catch(error => {
                        // TODO: do something
                        this.error = error;
                    });
            },
            updateSong: function() {
                const axiosConfig = {
                    headers: {'X-Requested-With': 'XMLHttpRequest'},
                };

                const data = {
                    id: this.song.id,
                    title: this.songTitle,
                    text: this.songText,
                    authors: this.songAuthors,
                };

                axios.put(this.getSongUrl, data, axiosConfig)
                    .then(response => {
                        response;
                        //this.song = response.data;

                        this.showSuccessMessage = true;
                        this.successMessage = 'Song updated!';
                    })
                    .catch(error => {
                        // TODO: do something
                        this.error = error;
                    });
            }
        },
        created: function() {
            this.loadSong();
        }
    }
</script>

<style>
    textarea {
        font-size: 13px;
        font-family:Consolas,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New, monospace;
    }
</style>
