<script src="store/modules/currentUser.js"></script>
<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
    >
      <v-list dense>
        <v-list-item link :to="{ name: 'Home' }" exact>
          <v-list-item-action>
            <v-icon>mdi-home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link :to="{ name: 'Songs' }" exact>
          <v-list-item-action>
            <v-icon>mdi-folder-music</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Songs</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="mt-6"></v-divider>

        <v-list-item v-if="!isLoggedIn" link :to="{ name: 'Login' }" exact>
          <v-list-item-action>
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Log In</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-else link :to="{ name: 'Logout' }" exact>
          <v-list-item-action>
            <v-icon>mdi-exit-to-app</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Log Out</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      dense
      color="blue-grey darken-3"
      dark
      clipped-left
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title>{{ appName }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-chip v-if="isLoggedIn">
        <v-avatar left>
          <v-icon>mdi-account-circle</v-icon>
        </v-avatar>
        {{ currentUser.username }}
      </v-chip>

    </v-app-bar>

    <v-content>
      <v-container
        fluid
      >

        <v-row
          align="center"
          justify="center"
        >
          <v-col cols="12" lg="10" xl="8" class="pt-0">
            <v-breadcrumbs :items="breadcrumbs" large class="py-3 px-0">
              <template v-slot:divider>
                <v-icon>mdi-chevron-right</v-icon>
              </template>
            </v-breadcrumbs>
            <router-view></router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
    <v-footer
      color="blue-grey darken-3"
      app
      dark
    >
      <v-col
        class="text-center"
        cols="12"
      >
        <span>{{ appName }} &copy; 2020 Max Billingsley III</span>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    props: {
      source: String,
    },

    data: () => ({
      drawer: null,
      appName: 'Chord Charm',
      baseUrl: '/',
      breadcrumbs: [],
    }),
    computed: {
      ...mapGetters([
        'currentUser',
        'isLoggedIn',
      ])
    },
    methods: {
      loadBreadcrumbsForRoute: function() {
          this.breadcrumbs = this.$route.meta.breadcrumbs;
      },
      loadCurrentUser: function() {
        this.$store.dispatch('fetchCurrentUser', { 'baseUrl': this.baseUrl });
      }
    },
    created: function() {
      this.loadBreadcrumbsForRoute();
      this.loadCurrentUser();
    },
    watch: {
      '$route' () {
            this.loadBreadcrumbsForRoute();
        }
    }
  }
</script>
