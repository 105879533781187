<template>
    <v-card>
        <v-card-title>
            Welcome
        </v-card-title>
        <v-card-text>
            <p>
                Welcome to {{ appName }}; it works like a charm!
            </p>
            <p class="mt-8" v-if="!isLoggedIn">
                <v-btn large min-width="280" :to="{ name: 'Login' }">Click Here to Log In!</v-btn>
            </p>
            <p v-else>
                Thank you for joining us, {{ currentUser.username }}!
            </p>
        </v-card-text>
    </v-card>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        data: () => ({
            appName: 'Chord Charm',
        }),
        computed: {
            ...mapGetters([
                'currentUser',
                'isLoggedIn',
            ])
        }
    }
</script>