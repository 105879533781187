import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../store'

import Home from '../views/Home'
import Login from '../views/Login'
import Logout from '../views/Logout'
import Songs from '../views/Songs'
import ManageSong from '../views/ManageSong'

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            requiresAuth: false,
            breadcrumbs: [
                { text: 'Home', disabled: true }
            ]
        }
    },
    {
        path: '/songs',
        name: 'Songs',
        component: Songs,
        meta: {
            requiresAuth: true,
            breadcrumbs: [
                { text: 'Home', to: { name: 'Home' }, exact: true },
                { text: 'Songs', disabled: true }
            ]
        }
    },
    {
        path: '/songs/manage-song/:id',
        name: 'Manage Song',
        component: ManageSong,
        props: true,
        meta: {
            requiresAuth: true,
            breadcrumbs: [
                { text: 'Home', to: { name: 'Home' }, exact: true },
                { text: 'Songs', to: { name: 'Songs' }, exact: true },
                { text: 'Manage Song', disabled: true }
            ]
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            requiresAuth: false,
            breadcrumbs: [
                { text: 'Home', to: { name: 'Home' }, exact: true },
                { text: 'Log In', disabled: true }
            ]
        }
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout,
        meta: {
            requiresAuth: false,
            breadcrumbs: [
                { text: 'Home', to: { name: 'Home' }, exact: true },
                { text: 'Log Out', disabled: true }
            ]
        }
    },
]

const router = new VueRouter({
  routes: routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters.isLoggedIn) {
      next({ name: 'Login' })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next()
  }
})

export default router;
