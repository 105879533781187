<template>
    <v-card>
        <v-card-title>
            Log Out
        </v-card-title>
        <v-card-text>
            <p>
                You need to log out!
            </p>
            <p class="mt-8">
                <v-btn large min-width="280" :href="getAdminLogoutUrl">Log Out via Django Admin</v-btn>
            </p>
        </v-card-text>
    </v-card>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        data: () => ({
            baseUrl: '/',
        }),
        computed: {
            getAdminLogoutUrl: function() {
                return this.baseUrl + 'admin/logout/?next=/';
            },
            ...mapGetters([
                'currentUser',
                'isLoggedIn',
            ])
        }
    }
</script>