<template>
  <div>
      <v-snackbar
            v-model="showSuccessMessage"
            top
            right
            color="success"
            class="mt-12"
        >
          <span>{{ successMessage }}</span>
      </v-snackbar>
      <v-dialog
              v-model="showAddSongForm"
              max-width="600"
              persistent
      >
          <add-song-form
                  @add-song="handleAddedSong"
                  @canceled-add-song="handleCanceledAddSong"
          ></add-song-form>
      </v-dialog>
    <v-card>
        <v-card-title>
            Songs
        </v-card-title>
        <v-btn
              small
              absolute
              dark
              fab
              top
              right
              color="primary"
              @click.stop="openAddSongForm"
            >
              <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-card-text>
            <v-data-table
                    :headers="headers"
                    :items="songs"
            >
                <template v-slot:item.title="{ item }">
                    <router-link :to="{ name: 'Manage Song', params: { id: item.id }}">{{ item.title }}</router-link>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import axios from "axios";
  import AddSongForm from "../components/AddSongForm";

  export default {
    props: {
      source: String,
    },
    components: {
        'add-song-form': AddSongForm,
    },
    data: () => ({
      baseUrl: '/',
      headers: [
        {
            text: 'Title',
            value: 'title',
        },
        {
            text: 'Authors',
            value: 'authors',
        }
      ],
      songs: [],
      error: null,
      showAddSongForm: false,
      showSuccessMessage: false,
      successMessage: '',
    }),
    computed: {
        getSongsUrl: function() {
            return this.baseUrl + 'songs/api/songs/';
        },
      ...mapGetters([
        'currentUser',
        'isLoggedIn',
      ])
    },
    methods: {
      loadSongs: function() {
        const axiosConfig = {
            headers: {'X-Requested-With': 'XMLHttpRequest'},
        };

        axios.get(this.getSongsUrl, axiosConfig)
            .then(response => {
                this.songs = response.data;
            })
            .catch(error => {
                // TODO: do something
                this.error = error;
            });
      },
      openAddSongForm: function() {
          this.showAddSongForm = true;
      },
      handleAddedSong: function() {
          this.loadSongs();
          this.successMessage = 'Added song!';
          this.showSuccessMessage = true;
          this.showAddSongForm = false;
      },
      handleCanceledAddSong: function() {
          this.showAddSongForm = false;
      }
    },
    created: function() {
      this.loadSongs();
    },
  }
</script>
